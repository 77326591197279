import { APOData } from "./BuilderProps";
import { PostAPI_PutAPI_method } from "./Api";
import { APO__bookappointment } from "./PaymentDetail";
import { setBtnLoader, updateHandlebarsTemp } from "./CommonMethod";
import { loadStripe } from "@stripe/stripe-js";

// Function to create a payment intent using appointment data
const createPaymentIntent = async () => {
  try {
    const { APO_selectedData, stripeData, onboardingData } = APOData;
    const { appointmentJSON } = APO_selectedData;
    const data = {
      amount: appointmentJSON.price,
      // currency: onboardingData.userCurrencyCode,
      currency: "USD",
      // stripeAccountId: stripeData.stripeAccountId,
      stripeAccountId: "acct_1PffoVFR6rCIFCnu",
    };
    const IntentsResponse = await PostAPI_PutAPI_method("PaymentIntents", "POST", data);
    return IntentsResponse.data;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    // throw new Error("Failed to create payment intent.");
  }
};

// Function to setup Stripe and handle the payment process
export const setupStripe = async () => {
  try {
    const { APO_selectedData, selectedLocation, stripeData } = APOData;

    // Load the Stripe object with your public key
    // const stripe = await loadStripe(stripeData.StripePublishableKey);
    const stripe = await loadStripe("pk_test_51PTiWXJyWJAMyGw1KE7jab35H5lCpdZpPtfa3pdTLl4pjmFsGcwLU8Frcxn5eXr5VqflIeWiuxO0J8aupCV0EBF300xhT42u1E");

    // appointment booking/pay with satus
    APOData.selectedPaymentMethod = "pay_with_card";

    // Create the payment intent and get the client secret
    const response = await createPaymentIntent();
    const clientSecret = response.client_secret;

    // Select the booking content element and update it with the payment template
    const bookingContentElement = document.querySelector(
      ".APO_booking_content"
    );
    const template = document.getElementById("template_checkout").innerHTML;
    await updateHandlebarsTemp(bookingContentElement, template, {
      ...APO_selectedData,
      selectedLocation,
      paymentModeName: "stripe",
    });

    // Setup payment form elements
    const form = document.getElementById("stripe-checkout-form");
    const submitButton = document.getElementById("submit");
    const errorMessage = document.getElementById("error-message");
    const paymentMessage = document.getElementById("payment-message");

    // Define Stripe elements options
    const options = {
      layout: {
        type: "tabs",
        defaultCollapsed: false,
      },
    };
    const appearance = {
      theme: "stripe",
    };
    const elements = stripe.elements({ clientSecret, appearance });

    // Create and mount the payment element
    APOData.stripePaymentElement = elements.create("payment", options);
    APOData.stripePaymentElement.mount("#payment-element");

    // Handle form submission
    form.addEventListener("submit", async (event) => {
      event.preventDefault();
      submitButton.disabled = true;

      // loader
      setBtnLoader(true, event.submitter, [27]);

      try {
        if (APOData.selectedPaymentMethod === 'skip_payment') {
          await APO__bookappointment(false);
        }
        else {
          const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {}, // Do not set return_url
            redirect: "if_required", // Use 'if_required' to stay on the same page
          });
  
          if (error) {
            // Show error message
            errorMessage.innerText = error.message;
            errorMessage.classList.remove("hidden");
          } else {
            handlePaymentStatus(paymentIntent);
          }
        }
        submitButton.disabled = false;
        setBtnLoader(false, event.submitter);
      } catch (error) {
        console.error("Payment confirmation error:", error);
        errorMessage.innerText = "An error occurred. Please try again.";
        errorMessage.classList.remove("hidden");
        submitButton.disabled = false;
        setBtnLoader(false, event.submitter);
      }
    });

    const handlePaymentStatus = async (paymentIntent) => {
      if (paymentIntent.status === "succeeded") {
        await APO__bookappointment(paymentIntent);
      } else if (paymentIntent.status === "processing") {
        paymentMessage.textContent = "Your payment is processing.";
      } else {
        paymentMessage.textContent =
          "Payment not successful. Please try again.";
      }
      paymentMessage.classList.remove("hidden");
      errorMessage.classList.add("hidden");
    };
  } catch (error) {
    console.error("Setup Stripe error:", error);
  }
};
