import { APOData, templateJSON } from "./BuilderProps";
import { find } from 'lodash-es';

export const Set__APOtype = async () => {
  const hash = window.location.hash;
  const searchParams = new URLSearchParams(hash).get("type");
  return searchParams ? searchParams : APOData.appointmentFilter[0].typeName;
};
export const serviceQueryParams = async (params) => {
  try {
    const searchParams = new URLSearchParams(params);

    // Extract query parameters into a structured object
    APOData.APO_selectedData.serviceQParams = {
      step: searchParams.get("step"),
      products: searchParams.get("products"),
      type: searchParams.get("type"),
      staff: searchParams.get("staff"),
      slot: searchParams.get("slot"),
      staffSelected: searchParams.get("staffSelected"),
      locId: searchParams.get("locId"),
      anyProvider: searchParams.get("anyProvider"),
      re: JSON.parse(searchParams.get("re")),
      bid: searchParams.get("bid"),
    };
    let { APO_selectedData, slugType, locationList } = APOData;
    let { step, slot, type, products, staff, staffSelected, locId, anyProvider, re, bid } = APO_selectedData.serviceQParams;

    // Update APOData and fetch related data based on the query params
    if(slugType !== "meeting"){
      const { steps: defaultSteps } = templateJSON?.StyleObj["Service"];
      APO_selectedData.serviceActiveStep = (step) ? step : defaultSteps[0].name;
    }

    APO_selectedData.timeslot = (slot) ? slot : null;
    APOData.APO_selectedData.staffID = staff;

    if (type) {
      APOData.APOtype = type === "Appointment" ? "Service" : type;
    }

    if(products) {
      await updateSelectAPOJson(products);
    }

    if(staff && slugType !== "meeting") {
      await updateStaffJson(staff);
    }

    if (staffSelected) {
      APOData.APO_selectedData.staffSelected = JSON.parse(staffSelected);
    }

    if (locId) {
      APOData.selectedLocation = locationList.find(item => item.id === locId);
    }

    if (anyProvider) {
      APOData.APO_selectedData.anyProvider = JSON.parse(anyProvider);
    }

    if (re) {
      APOData.reschedule = re;
      APOData.bookingId = bid;
    }

    return APO_selectedData.serviceQParams;
  } catch (error) {
    console.error("Error processing service query parameters:", error);
    throw error;
  }
};

// Update APOData with selected product information
const updateSelectAPOJson = async (apptId) => {
  const { APOtype, APO_selectedData } = APOData;

  if (apptId) {
    APO_selectedData.appointmentJSON = APOData[APOtype]?.find(item => item.id == apptId); // Find the data by ID;
  }
  else {
    APO_selectedData.appointmentJSON = null;
  }
};

// Update APOData with selected staff information
export const updateStaffJson = async (staffId) => {
  const { onboardingData, APO_selectedData } = APOData;
  if (staffId) {
    const staffData = find(onboardingData.staffMembersList, { id: staffId }); // Find the data by ID
    APO_selectedData.staffJSON = staffData;
  }
  else {
    APO_selectedData.staffJSON = null;
  }
};

export const getServiceNextStep = async (obj) => {
  const { serviceActiveStep } = APOData.APO_selectedData;
  const { steps } = templateJSON.StyleObj["Service"];
  const stepIndex = steps.findIndex((step) => step.name === serviceActiveStep);
  const nextpreStep = {
    nextStep: obj ? steps[stepIndex + 1].name : serviceActiveStep,
  };

  return nextpreStep;
};

// export const getpreviouStep = async() => {
//   const { staffRules, APO_selectedData, } = APOData;
//   const { serviceActiveStep } = APO_selectedData;
//   const {appointmentJSON, staffJSON} = APO_selectedData;
//   const { steps } = templateJSON.StyleObj["Service"];

//   let previouStep = ""
//   if ((staffRules.length && serviceActiveStep === "datetime") || serviceActiveStep === "stafflist") {
//     previouStep = "#/book";
//   }
//   else {
//     const stepIndex = steps.findIndex(step => step.name === serviceActiveStep);
//     previouStep = `#/book/step=${steps[stepIndex - 1]}&products=${appointmentJSON.id}&type=${appointmentJSON.APOtype}&staff=${staffJSON.id}`;
//   }

//   return previouStep;

// };
