import moment from "moment";
import { toZonedTime } from "date-fns-tz";
import { APO_previewDate, serviceDuration } from "../Calendar";
import { GetAPI_deleteAPI_method } from "../Api";
import { hideLoaderAfterPageLoad, updateHandlebarsTemp } from "../CommonMethod";
import { template_meetingCalendar } from "./MeetingHandlebars-template";

export const bindCalendarSection = async () => {
  // Select the meeting content element
  const insertTemplate = document.querySelector(".TM-booking_content");

  // Update the booking content with the Handlebars template and order details
  await updateHandlebarsTemp(insertTemplate, template_meetingCalendar(), "");
};

export const initMeetingCalendar = async () => {
  const { APO_selectedData, businessTimeZone } = APOData;
  const { businessHoursList } = APO_selectedData.staffJSON;

  // find the open day from provider business hours
  let openedDay = await APO_previewDate(businessHoursList);

  // Generate a comma-separated list of disabled days based on business hours
  const disabledDays = businessHoursList
    .map((hours, index) => (!hours.isOpened ? (index + 1) % 7 : null))
    .filter((day) => day !== null)
    .join(",");

  // Initialize the date picker with the desired configuration
  $("#meetind_calendar")
    .datepicker({
      daysOfWeekDisabled: disabledDays,
      format: "mm-dd-yyyy",
      startDate: toZonedTime(moment().format(), businessTimeZone),
      weekStart: 1,
      todayHighlight: false,
      templates: {
        leftArrow: '<i class="fa fa-angle-left"></i>',
        rightArrow: '<i class="fa fa-angle-right"></i>',
      },
      beforeShowDay: function (date) {},
    })
    .on("changeDate", async function (e) {
      let selectedDay = new Date(e.date);
      let updateSelectedDay = moment();
      // Update the moment date with the new date
      updateSelectedDay
        .year(selectedDay.getFullYear())
        .month(selectedDay.getMonth())
        .date(selectedDay.getDate());

      APO_selectedData.date = moment(updateSelectedDay).format();

      // Remove the 'today' class from all days in the date picker to avoid styling conflicts
      document
        .querySelectorAll(".datepicker-days .day")
        .forEach((day) => day.classList.remove("today"));

      //update selected meeting date dom element
      document.querySelector(".SelectedMeetingDate").innerHTML = moment(APO_selectedData.date).format('ddd, MMM DD, YYYY');

      // Update service duration based on the newly selected day
      serviceDuration(updateSelectedDay);
    });

  // Set the date picker to display the initially selected day
  if (openedDay) {
    $("#meetind_calendar").datepicker("setDate", moment(openedDay).format("l"));
  }

  hideLoaderAfterPageLoad();
};
