
// global variable
export let APOData = {
  onboardingData: null,
  Bookingrules: null,
  Service: [],
  Event: [],
  Class: [],
  APO_selectedData: {
    appointmentID: null,
    staffID: null,
    date: null,
    timeslot: null,
    appointmentJSON: null,
    staffJSON: null,
    serviceQParams: {},
  },			
  APOtype: "",
  CountriesList: [],
  daysOfWeek: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  slugType: null,
};
export const templateJSON = {};
globalThis.APOData = APOData;
globalThis.templateJSON = templateJSON;

export const ZoomIcon = "https://appol-resources.s3.eu-west-1.amazonaws.com/SiteImages/ZoomIcon.svg"
export const GoogleMeet = "https://appol-resources.s3.eu-west-1.amazonaws.com/SiteImages/GoogleMeet.svg";
export const TeamsIcon = "https://appol-resources.s3.eu-west-1.amazonaws.com/SiteImages/TeamsIcon.svg"

