import moment from "moment";
import "moment-timezone";
import { APOData, GoogleMeet, TeamsIcon, ZoomIcon } from "../BuilderProps";
import {
  businessTimeZoneToUserTimezone,
  fetchCountriesList,
  fetchCustomerList,
  getUrlParams,
  updateHandlebarsTemp,
} from "../CommonMethod";
import { initMeetingCalendar } from "./MeetingCalendar";
import {
  template_meeting,
  template_UserD_placeholderLoading,
  template_UserDetailForm,
} from "./MeetingHandlebars-template";
import { meetingRouting } from "./MeetingRoutes";
import { bookingForm_EventListeners } from "../SignupFrom";
import { meeting_Bookappointment } from "./MeetingBookingDetail";
import { applyBookingRulesToDomElements, fetchBookingRules} from "../ThemeOnLoadSettings";
import { multiLocationPopup } from "../MultiLocation";
import { fetchSingleStaffData, fetchStaffHoursByLocation } from "../SingleStaff";

export const bindMeetingTemplate = async () => {
  // Select the meeting content element
  const insertTemplate = document.querySelector("#insertData");

  try {
    const { APO_selectedData} = APOData;
    
    // Define the valid meeting durations
    const defaultMeetingTimeArray = ["15", "30", "45", "60"]
    const meetingTime = await getUrlParams("time");
    
    // Fetch and apply booking rules
    await fetchBookingRules();

    // Fetch and update staff data
    const meetingstaffJSON = await fetchSingleStaffData(APO_selectedData.staffID);
    meetingstaffJSON.businessHoursList = await fetchStaffHoursByLocation(
      meetingstaffJSON.id,
      meetingstaffJSON.staffLocation[0]?.id
    );
    

    // Update global object with validated appointment details
    APOData.selectedLocation = meetingstaffJSON.staffLocation[0];
    APO_selectedData.staffJSON = meetingstaffJSON;
    APO_selectedData.appointmentJSON = {
      duration: defaultMeetingTimeArray.includes(meetingTime) ? meetingTime : "15",
    };

    const { staffJSON } = APO_selectedData;

    // match meeting time in defaultHoursList list and update type name and icon
    const matchedTime = staffJSON.defaultHoursList.find(hour => hour.time == meetingTime);
    if (matchedTime) {
      matchedTime.meetingTypeName = matchedTime.meetingType === "GoogleMeet" ? "Google Meet" : matchedTime.meetingType;
      matchedTime.meetingTypeIcon = matchedTime.meetingType === "GoogleMeet" ? GoogleMeet : matchedTime.meetingType === "Teams" ? TeamsIcon : ZoomIcon;
    }

    //update current meeting JSON globally
    APOData.matchedTime = matchedTime;

    // Prepare data for Handlebars template
    const templateData = { 
      staffJSON,
      matchedTime
    };

    // Render the Handlebars template into the target element
    await updateHandlebarsTemp(
      insertTemplate,
      template_meeting(),
      templateData
    );

    // Initialize routing, calendar, and apply booking rules
    meetingRouting();
    // await multiLocationPopup(staffData)
    // await initMeetingCalendar();
    await applyBookingRulesToDomElements()

  } catch (error) {
    console.error("Error in bindMeetingTemplate:", error);
  }
};

export const meeting_UserDetails = async () => {
  // Select the meeting content element
  const insertTemplate = document.querySelector(".TM-booking_content");

  // Destructure necessary properties and APOData
  const { CountriesList, APO_selectedData, localpInfo } = APOData;

  // Check if CountriesList is empty and populate it if needed
  if (CountriesList.length === 0) {
    await fetchCountriesList(
      insertTemplate,
      template_UserD_placeholderLoading()
    );
  }

  // Check if AllcustomerID is not set and populate it if needed
  if (!APOData.AllcustomerID) {
    await fetchCustomerList();
  }

  // Prepare data for the Handlebars template slugType
  const templateData = {
    CountriesList,
    localpInfo
  };

  // Update the booking content with the Handlebars template and order details
  await updateHandlebarsTemp(insertTemplate, template_UserDetailForm(), templateData);

  // Initialize custom select elements with search functionality
  $(".DropwithSearch").customSelect({ search: true });

  const selectedMeetingDate = moment.unix(APO_selectedData.timeslot).format('ddd, MMM DD, YYYY');
  const selectedMeetingTime = moment.unix(APO_selectedData.timeslot).format('HH:mm');
  
  // Convert time slots from business timezone into user timezone
  const convertToUserTimezone = await businessTimeZoneToUserTimezone(selectedMeetingTime);
  APO_selectedData.convertedUserTimeSlot = convertToUserTimezone;

  //update selected meeting date dom element
  document.querySelector(".SelectedMeetingDate").innerHTML = `${selectedMeetingDate} @ ${convertToUserTimezone}`

  // Attach event listeners for the booking form
  bookingForm_EventListeners();
};

window.meetingBookingContactForm = async (event, element) => {
    event.preventDefault(); // Prevent default form submission
  
    const formData = new FormData(event.target);
  
    APOData.userDetail = {
      name: formData.get("name"),
      emailAddress: formData.get("email"),
      phoneNumber: "+" + formData.get("country_code") + formData.get("number"),
      country: formData.get("country"),
      listId: APOData.AllcustomerID,
    };
  
    // Select the meeting content element
    const insertTemplate = document.querySelector(".TM-booking_content");

    await updateHandlebarsTemp(insertTemplate, template_UserD_placeholderLoading(), "");

    meeting_Bookappointment();
  
  };
