import { GetAPI_deleteAPI_method } from "./Api";
import { APOData } from "./BuilderProps";
import { compiledHandlebarsTemp, updateHandlebarsTemp } from "./CommonMethod";
import { getServiceNextStep } from "./Serivceconfig";
import {
  bindCalendarSection,
  initMeetingCalendar,
} from "./teamMeeting/MeetingCalendar";
import { template_locationPopup } from "./teamMeeting/MeetingHandlebars-template";


export const initializeLocationPopupListeners = () => {
  // JavaScript for modal functionality
  const APO_modalOverlay = document.getElementById("APO_modalOverlay");
  const APO_closeModalBtns = document.querySelectorAll(".APO_closeModal");

  // Close modal
  APO_closeModalBtns.forEach((btn) => {
    btn.addEventListener("click", () => {
      APO_modalOverlay.style.display = "none";
    });
  });

  // Close modal on clicking overlay
  APO_modalOverlay.addEventListener("click", (e) => {
    if (e.target === APO_modalOverlay) {
      APO_modalOverlay.style.display = "none";
    }
  });
};

export const multiLocationPopup = async (staffData) => {
  try {
    const { serviceQParams } = APOData.APO_selectedData;

    if (!serviceQParams) {
      const templateData = {
        ...staffData,
        locId: APOData.selectedLocation.id,
      };

      // Compile the Handlebars template
      const popupHtml = await compiledHandlebarsTemp(
        template_locationPopup(),
        templateData
      );

      // Insert the popup HTML into the DOM
      document.body.insertAdjacentHTML("beforeend", popupHtml);

      // Attach event listeners for the booking form
      initializeLocationPopupListeners();
    }
  } catch (error) {
    console.error(
      "Error rendering Location popup with staffData:",
      staffData,
      error
    );
  }
};

// export const fetchStaffHoursByLocation = async () => {
//   try {
//     const { staffJSON } = APOData.APO_selectedData;

//     // Retrieve or initialize locationId
//     let locationId = JSON.parse(localStorage.getItem("9e418fc983-data"));

//     if (!locationId?.locID) {
//       locationId = staffJSON.staffLocation[0].id;
//       localStorage.setItem(
//         "9e418fc983-data",
//         JSON.stringify({ locID: locationId })
//       );
//     }
//     APOData.selectedLocationId = locationId?.locID;

//     // Fetch business hours based on location and staff ID
//     const response = await GetAPI_deleteAPI_method(
//       `BusinessHoursByLocation?LocationId=${APOData.selectedLocationId}&StaffId=${staffJSON.id}`,
//       "GET"
//     );

//     if (response?.data) {
//       staffJSON.businessHoursList = [...response.data.businessHoursLists];
//       $("#meetind_calendar").datepicker("destroy");
//       //   bindCalendarSection()
//       await initMeetingCalendar(); // Initialize calendar
//     }
//   } catch (error) {
//     console.error("Error fetching hours By Location:", error);
//   }
// };

export const fetchAllLocations = async (slugType) => {
  try {
     // Destructure required data from APOData
     const { APO_selectedData } = APOData;
    if(slugType === "team") {
      const { staffJSON } = APO_selectedData;
      APOData.locationList = staffJSON.staffLocation;
    }
    else {
      // Fetch location list
      const response = await GetAPI_deleteAPI_method(`LocationFilter`,"GET");
  
      //Validate response structure
      APOData.locationList =  response?.data ?? [];
    }

    //Updates the location in APOData if a single location exists or remove locationlist step.
    const { steps } = templateJSON.StyleObj["Service"];
    if(APOData.locationList.length === 1) { 
  
      const { id, ...locationDetails } = APOData.locationList[0];
      APOData.selectedLocation = { id, ...locationDetails };
      APO_selectedData.serviceQParams.locId = id;
      steps.splice(0, 1);
      
    }
    //update service active step 
    APO_selectedData.serviceActiveStep = steps[0].name;

  } catch (error) {
    console.error("Error fetching Location list:", error);
  }
}

export const bindLocationLayout = async () => {
  try {

    // Get the target element and template & Handlebars template
    const appendElement = document.querySelector(`.APO_booking_content`);
    const template = document.getElementById(`template_locationList`).innerHTML;

    // Fetch the next step
    const { nextStep: serviceNextStep } = await getServiceNextStep(true);

    // Prepare data for the template
    const templateData = {
      locationList: APOData.locationList,
      step: serviceNextStep,
    };

    // Compile and update the Handlebars template
    await updateHandlebarsTemp(appendElement, template, templateData);
  } catch (error) {
    console.error("Error in bindLocationLayout:", error);
  }
};

// Update staff location selection
window.updateStaffLocation = (event) => {
  // Get the parent element (UL) containing all LI elements
  const { target } = event;
  const { parentElement } = target.parentElement;

  // Remove 'selected' class from all siblings
  parentElement
    .querySelectorAll("li")
    .forEach((li) => li.classList.remove("selected"));

  // Add 'selected' class to the clicked element
  target.classList.add("selected");

  // Update the selected location data attribute
  document
    .getElementById("saveStaffLocation")
    .setAttribute("data-selectedlocation", target.id);
};

// Save staff location and update business hours
// window.saveStaffLocation = async (event) => {
//   const { target } = event;
//   const selectedLocationId = target.dataset.selectedlocation;

//   localStorage.setItem(
//     "9e418fc983-data",
//     JSON.stringify({ locID: selectedLocationId })
//   );

//   //if (APOData.locationId !== selectedLocationId) {
//     APOData.selectedLocationId = selectedLocationId;
//     await fetchStaffHoursByLocation();
//   //}

//   // Hide the modal overlay
//   const modalOverlay = document.getElementById("APO_modalOverlay");
//   if (modalOverlay) {
//     modalOverlay.style.display = "none";
//   }
// };
