import {
  GetAPI_deleteAPI_method,
  PostAPI_PutAPI_method,
} from "./Api";
import { APOData, templateJSON } from "./BuilderProps";
import { setBtnLoader, updateHandlebarsTemp } from "./CommonMethod";
import {
  showValidationMessages,
  toggleSubmitButton,
  validateField,
} from "./FormValidation";
import { filterBookingRule } from "./ThemeOnLoadSettings";
import moment from "moment";

window.cancelAppointment = async (event) => {
  try {
    const {id} = event.currentTarget.dataset;

    // Show loader on the clicked element
    setBtnLoader(true, event.currentTarget, [27]);

    // Select the booking wrapper element
    const BookingWrapper = document.querySelector(".Booking .BK-wrapper");

    // Get the placeholder template for loading state
    const placeholderTemplate = document.getElementById("template_bookedAppointmentCancel")?.innerHTML;

    // Call the API to cancel the appointment and delete it
    const response = await GetAPI_deleteAPI_method(`Appointments?AppointmentId=${id}`, "DELETE");
    // const response = {
    //   "id": "APPLMJ1JDKK",
    //   "success": true,
    //   "message": "Cancelled successfully"
    // }

    // Check if the API response is successful
    if (response?.status === 200) {
      // Update the booking wrapper with the response data using the Handlebars template
      await updateHandlebarsTemp(BookingWrapper, placeholderTemplate, response.data);
    }
  } catch (error) {
    console.error("Error occurred while cancelling appointment:", error);
  } finally {
    // Hide the loader on the button after API call is complete
    setBtnLoader(false, event.currentTarget);
  }
};

const searchbookedappointmentLoader = async () => {
  // Select the booking wrapper element
  const BookingWrapper = document.querySelector(".Booking .BK-wrapper");

  // Get the placeholder template for loading state
  const placeholderTemplate = document.getElementById("template_bookingPlaceholderLoader").innerHTML;

  // Show the placeholder while fetching data
  await updateHandlebarsTemp(BookingWrapper, placeholderTemplate, "");
}

export const bookedAppointmentsList = async (data, message) => {
  try {
    const { slugType, APO_selectedData } = APOData;
    const { staffID } = APO_selectedData;

    // Select the booking wrapper element
    const BookingWrapper = document.querySelector(".Booking .BK-wrapper");

    // Fetch appointment data from API
    // const response = await GetAPI_deleteAPI_method("Appointments", "GET");

    // Destructure necessary data from APOData
    const { steps } = templateJSON.StyleObj["Service"];

    // Filter booking rules to get cancellation and rescheduling rules
    const allowedKeys = [
      "IsAllowOnlineRescheduling",
      "IsAllowOnlineCancellation",
    ];
    const rule_CancelResdul = await filterBookingRule(allowedKeys);

    // Get the template for booked appointment list
    const template = document.getElementById(
      "template_bookedAppointmentList"
    ).innerHTML;

    const stepIndex = steps.findIndex((step) => step.name.includes("datetime"));

    // Check if searching for bookings from a different team member than the current one
    const BookingData = data.map((item) => ({
      ...item,
      isCurrentMemberBooking: (slugType === "team") ? item.staffId == staffID: true,
    }));

    // Prepare data for the template
    const templateData = {
      BookingData,
      rule_CancelResdul,
      step: steps[stepIndex].name,
      StatusMessage: message,
    };

    // Update the booking wrapper with the new content
    await updateHandlebarsTemp(BookingWrapper, template, templateData);
  } catch (error) {
    console.error("Failed to fetch booked appointments:", error);
    // Handle error appropriately (e.g., show an error message)
  }
};

export const findBookingFormListeners = () => {
  const findBookingForm = document.getElementById("bookedApo_searchFrom");
  const submitButton = findBookingForm.querySelector('button[type="submit"]');

  // Define validation schema for form fields
  const validationSchema = {
    bookingInfo: [{ type: "isRequired" }],
  };

  // Common validation handler function
  const handleValidation = (event) => {
    const { name, value } = event.target;

    if (validationSchema[name]) {
      const { isValid, errorMessage } = validateField(
        name,
        value,
        validationSchema
      );
      //showValidationMessages(event.target, errorMessage);
      toggleSubmitButton(findBookingForm, validationSchema, submitButton);
    }
  };

  // Attach event listeners to the form (event delegation)
  findBookingForm.addEventListener("keyup", handleValidation);
};

// Function to search appointments with provided data
const searchbookedappointment = async (postData) => {
  try {
    const response = await PostAPI_PutAPI_method("SearchAppointments", "POST", postData);
    
    let searchData = [];
    let statusMessage = null;
    
    if (response.status === 200 && response.data.length) {

      searchData = response.data.map(appointment => ({
        ...appointment,
        isOldAppointment: moment().isAfter(moment(appointment.startDate))
      }));

    } else {
      statusMessage = "Booking not found!";
    }
    
    bookedAppointmentsList(searchData, statusMessage); // Display appointments
    return response;
  } catch (error) {
    console.error("Error in finding booking:", error);
    bookedAppointmentsList([], "Error in finding booking"); // Display error
  }
};

// Function to handle appointment search from a form
window.searchAppointmentFrom = async (event) => {
  event.preventDefault(); // Prevent default form submission

  setBtnLoader(true, event.submitter, [27]); // Show loader on submit button

  const formData = new FormData(event.target);
  const data = {
    searchText: formData.get("bookingInfo"),
    tokenId: APOData.Tid,
  };

  const result = await searchbookedappointment(data);

  //if (result.status === 200 && result.data.length) {
    event.target.reset(); // Reset form on successful submission
  //}

  setBtnLoader(false, event.submitter); // Hide loader
};

// Function to find a single booking by ID
export const findSingleBooking = async (bookingId) => {
  const data = {
    searchText: bookingId,
    tokenId: APOData.Tid,
  };

  // router.navigate("/bookings/type/upcoming"); // Navigate to upcoming bookings
  await searchbookedappointment(data);
};
