import { APOData } from "./BuilderProps";
import { GetAPI_deleteAPI_method, PostAPI_PutAPI_method } from "./Api";
import moment from "moment";

// Fetch staff list by location
export const fetchStaffByLocation = async () => {
  try {
    const { selectedLocation, onboardingData } = APOData;
    const filterLocation = {
      locationNames: [
        {
          id: selectedLocation.id,
          name: selectedLocation.locationName,
        },
      ],
    };

    // Fetch business hours based on location and staff ID
    const response = await PostAPI_PutAPI_method(
      `FilterByLocation`,
      "POST",
      filterLocation
    );

    onboardingData.staffMembersList = response?.data ?? [];
  } catch (error) {
    console.error("Error fetching staff list By Location:", error);
  }
};
// Fetch business hours by location
export const fetchStaffHoursByLocation = async (staffId, locId) => {
  try {
    // Fetch business hours based on location and staff ID
    const response = await GetAPI_deleteAPI_method(
      `BusinessHoursByLocation?LocationId=${locId}&StaffId=${staffId}`,
      "GET"
    );

    // Validate response structure
    return response?.data.businessHoursLists ?? [];
  } catch (error) {
    console.error("Error fetching hours By Location:", error);
  }
};

export const fetchSingleStaffData = async (staffId) => {
  try {
    // Destructure required data from APOData
    const { APO_selectedData, slugType } = APOData;

    // Call the API to get staff staff based on the ID
    const response = await GetAPI_deleteAPI_method(
      `StaffById?StaffId=${staffId}`,
      "GET"
    );

    if (response?.data) {
      const { data } = response;

      //globally set business time zone
      APOData.businessTimeZone = data.businessAddress[0].timeZone;
      moment.tz.setDefault(APOData.businessTimeZone);

      if(slugType === "meeting") { 
        const ipinfoRequest = await fetch(`https://ipinfo.io/json?token=${process.env.IPTRACKKEY}`);
        APOData.localpInfo = await ipinfoRequest.json();;
      }

      // Update the `staffLocation` array
      // data.staffLocation = data.staffLocation.map(({ name, ...rest }) => ({
      //   ...rest,
      //   locationName: name, // Rename `name` to `locationName`
      // }));

      // Update staffLocation with matching businessAddress data
      data.staffLocation = data.staffLocation.map(location => {
        const matchingAddress = data.businessAddress.find(address => address.id === location.id);
        return matchingAddress ? { ...location, ...matchingAddress } : location;
      });

      //update staffJSON and ID
      APO_selectedData.staffJSON = data;
      APO_selectedData.staffID = data.id;
      return data;
    }
  } catch (error) {
    console.error("Error initializing fetching staff data:", error);
    return null;
  }
};

export const fetchProviderCountData = async (locId) => {
  try {
    // Fetch provider count data
    const response = await GetAPI_deleteAPI_method("ProviderCount", "GET");
    const providers = response?.data ?? [];

    // Return early if no provider data is available
    if (!providers.length) {
      console.warn("No provider data available.");
      return [];
    }

    // Filter providers by location ID and sort by totalCount (ascending)
    const sortedProviders = providers
      .filter((provider) =>
        provider.staffLocation.some((location) => location.id === locId)
      )
      .sort((a, b) => a.totalCount - b.totalCount);

    return sortedProviders;
  } catch (error) {
    console.error("Error fetching provider count data:", error.message);
    return [];
  }
};
