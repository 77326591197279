import { findSingleBooking } from "./BookedAppointmentsList";
import { getUrlParams } from "./CommonMethod";

export const companySpecificInformation = async () => {
  const { APO_selectedData } = APOData;
  const staffId = APO_selectedData.staffID;
  const bookingId = await getUrlParams("bookingId");
  const { staffMembersList } = APOData.onboardingData;
  if (staffId) {
    APOData.onboardingData.staffMembersList = staffMembersList.filter(
      (staff) => staff.id === staffId
    );
  } else if (bookingId) {
    // Construct the new URL with the hash (or modify the URL as needed)
    const newUrl = `${window.location.origin}${window.location.pathname}${window.location.search}#/bookings/type/upcoming`;
    // Push the new URL to the browser's history stack
    history.replaceState(null, "", newUrl);
    findSingleBooking(bookingId);
  }
};
