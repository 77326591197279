import Navigo from "navigo";
import { bindCalendarSection, initMeetingCalendar } from "./MeetingCalendar";
import { serviceQueryParams } from "../Serivceconfig";
import { meeting_UserDetails } from "./MeetingTemplate";

export const router = new Navigo("/", { hash: true });

export const meetingRouting = () => {
  router
    .on({
      "/": async () => {
        await bindCalendarSection(); 
        await initMeetingCalendar();
      },
      "/:name": async () => {
        await bindCalendarSection(); 
        await initMeetingCalendar();
      },
      "/book": async () => { 
        await bindCalendarSection();  
        await initMeetingCalendar(); 
      },
      "/book/step=:step": async (params) => {
        const { data } = params;
        const stepsData = await serviceQueryParams(`step=${data.step}`);

        switch (stepsData.step) {
            case "userdetails":
            await meeting_UserDetails();
              break;
            default:
              console.warn("Unknown step:", step);
          }
      },
    })
    .resolve();
};
